import { BaseDto } from './base.dto'

export class FileDto extends BaseDto {
  public url: string
  public name: string
  public path: string
  public type: string
  public hasChildren: boolean
  public isDirectory: boolean

  static create() {
    return new FileDto()
  }
}
