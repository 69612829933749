import { CommonModule } from '@angular/common'
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { ModuleWithProviders, NgModule } from '@angular/core'

import { HttpErrorInterceptor } from '@api/common'
import { StylesFilterPipe } from '@api/utils'

import { AuthStore, AuthQuery } from '../store/auth'

import { HttpService, AuthService, LocalStorageService, FileService, UserService, ProfileService, PageService, SettingService, MembershipService, DoctorService, ServiceService, QuestionnaireService, AppointmentService, InvoiceService, DashboardService, AllowPhoneNumberService } from '@api/services'
import { ApiModuleOptions } from './api.module-options'
import { PackageService } from '../services/package.service'
import { DepartmentService } from '../services/department.service'
import { ArticleService } from '../services/article.service'
import { BannerService } from '../services/banner.service'
import { DragulaService } from 'ng2-dragula'
import { FeedbackService } from '../services/feedback.service'
import { SymptomService } from '../services/symptom.service'
import { WindowRef } from '../services/window-ref.service'
import { MMSLayoutService } from '../../@shared/layout'
import { LogsService } from '../services/logs.service'

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule
  ],
  declarations: [
    StylesFilterPipe
  ],
  providers:[
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    FileService,
    LocalStorageService,
    HttpService,
    AuthService,
    UserService,
    ProfileService,
    AuthStore,
    PageService,
    SettingService,
    MembershipService,
    PackageService,
    DepartmentService,
    ArticleService,
    BannerService,
    DoctorService,
    DragulaService,
    SymptomService,
    FeedbackService,
    ServiceService,
    QuestionnaireService,
    AppointmentService,
    AllowPhoneNumberService,
    DashboardService,
    MMSLayoutService,
    InvoiceService,
    LogsService,
    AuthQuery,
    WindowRef
  ],
  exports: [
    StylesFilterPipe
  ]
})
export class ApiModule {
  static forRoot(options: ApiModuleOptions): ModuleWithProviders<ApiModule> {
    // window['serverURL'] = options.serverURL
    
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiModuleOptions,
          useValue: options
        }
      ]
    }
  }
}
