import { Injectable } from '@angular/core'

import { HttpService } from './http.service'

import { PageResultDto, DoctorDto } from '../dto'
import { FindDoctorDto, FindLeaveHourDto, FindSpecialDayDto } from '../dto/doctor/find-doctor.dto'
import { PagingDto } from '../dto/common/paging.dto'
import { TimeSlotLeaveHourDto } from '../dto/timeslot/timeslot-leave-hour'
import { TimeSlotWorkingHourDto } from '../dto/timeslot/timeslot-working-hour.dto'
import { TimeSlotSpecialWorkingDayDto } from '../dto/timeslot/timeslot-special-working-day.dto'


@Injectable()
export class DoctorService {
  constructor(
    private httpService: HttpService) {
  }

  async create(createArticle: DoctorDto) {
    return await this.httpService.post<DoctorDto>(`/admin/doctors`, createArticle)
  }

  async find(findDoctorDto: FindDoctorDto) {
    return await this.httpService.get<PageResultDto<DoctorDto>>('/admin/doctors', findDoctorDto)
  }

  async findOne(doctorId: string) {
    return this.httpService.get<DoctorDto>(`/admin/doctors/${doctorId}`)
  }

  async update(doctorId: string, updateDoctor: DoctorDto) {
    return await this.httpService.put<boolean>(`/admin/doctors/${doctorId}`, updateDoctor)
  }

  async findWorkingHour(doctorId: string, pagingDto: PagingDto) {
    return await this.httpService.get<PageResultDto<TimeSlotWorkingHourDto>>(`/admin/doctors/${doctorId}/working-hour`, pagingDto)
  }

  async findSpecialWorkingDay(doctorId: string, findSpecialDayDto: FindSpecialDayDto) {
    return await this.httpService.get<PageResultDto<TimeSlotSpecialWorkingDayDto>>(`/admin/doctors/${doctorId}/special-working-day`, findSpecialDayDto)
  }

  async findLeaveHour(doctorId: string, findLeaveHourDto: FindLeaveHourDto) {
    return await this.httpService.get<PageResultDto<TimeSlotLeaveHourDto>>(`/admin/doctors/${doctorId}/leave-hour`, findLeaveHourDto)
  }

  async destroy(doctorId: string) {
    return this.httpService.delete<DoctorDto>(`/admin/doctors/${doctorId}`)
  }

  async sortOrder(doctorId: string, departmentIds: string[]) {
    return await this.httpService.put<Boolean>(`/admin/doctors/${doctorId}/description/reorder`, { departmentIds })
  }

  async destroyDescription(doctorId: string, descriptionId: string) {
    return this.httpService.delete<DoctorDto>(`/admin/doctors/${doctorId}/description/${descriptionId}`)
  }

  async published(id: string) {
    return await this.httpService.put(`/admin/doctors/${id}/published`)
  }

  async setEnabled(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.put(`/admin/doctors/${id}/enable`)
    }
    
    return await this.httpService.put(`/admin/doctors/${id}/disable`)
  }

}
