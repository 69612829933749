export class TranslationDto<T = any> {
  en: T
  kh: T

  public static create(en?: any, kh? : any) {
    const translation = new TranslationDto()

    translation.en = en || ''
    translation.kh = kh || ''

    return translation
  }
}