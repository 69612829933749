import * as _ from 'lodash'
import { FileDto } from '../common/file.dto'
import { TranslationDto } from '../common/translation.dto'
import { DepartmentDto } from '../department/department.dto'

export class SymptomDto {
  id:string
  title: TranslationDto
  description: TranslationDto
  departments: DepartmentDto[]
  departmentIds: string[]
  thumbnail: FileDto
  thumbnailId: string
  isEnabled: boolean

  static create() {
    const symptom = new SymptomDto()

    symptom.title = TranslationDto.create()
    symptom.description = TranslationDto.create()
    symptom.isEnabled = true
    symptom.departments = []
    symptom.departmentIds = []
    
    return symptom
  }
}
