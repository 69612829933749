import { Injectable } from '@angular/core'

import { HttpService } from './http.service'

import { PageResultDto, QuestionnaireDto } from '../dto'
import { PagingDto } from '../dto/common/paging.dto'
import { AnswerDto } from '../dto/questionnaire/questionnaire.dto'
import { FindQuestionnaireDto } from '../dto/questionnaire/find-questionnaire.dto'


@Injectable()
export class QuestionnaireService {
  constructor(
    private httpService: HttpService) {
  }

  async create(createQuestionnaire: QuestionnaireDto) {
    return await this.httpService.post<QuestionnaireDto>(`/admin/questionnaires`, createQuestionnaire)
  }

  async find(findQuestionnaireDto: FindQuestionnaireDto) {
    return await this.httpService.get<QuestionnaireDto[]>('/admin/questionnaires', findQuestionnaireDto)
  }

  async findOne(questionnaireId: string) {
    return this.httpService.get<QuestionnaireDto>(`/admin/questionnaires/${questionnaireId}`)
  }

  async update(questionnaireId: string, updateQuestionnaire: QuestionnaireDto) {
    return await this.httpService.put<boolean>(`/admin/questionnaires/${questionnaireId}`, updateQuestionnaire)
  }

  async destroy(questionnaireId: string) {
    return this.httpService.delete<QuestionnaireDto>(`/admin/questionnaires/${questionnaireId}`)
  }

  async sortOrder(questionIds: string[]) {
    return await this.httpService.put<Boolean>(`/admin/questionnaires/reorder`, { questionIds })
  }

  async setEnabled(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.put(`/admin/questionnaires/${id}/enable`)
    }
    
    return await this.httpService.put(`/admin/questionnaires/${id}/disable`)
  }

  async findAnswers(questionnaireId: string) {
    return this.httpService.get<AnswerDto[]>(`/admin/questionnaires/${questionnaireId}/answers`)
  }


  async createAnswer(questionnaireId: string, createQuestionnaire: AnswerDto) {
    return await this.httpService.post<QuestionnaireDto>(`/admin/questionnaires/${questionnaireId}/answers`, createQuestionnaire)
  }

  async updateAnswer(questionnaireId: string, answerId: string, updateQuestionnaire: AnswerDto) {
    return await this.httpService.put<boolean>(`/admin/questionnaires/${questionnaireId}/answers/${answerId}`, updateQuestionnaire)
  }

  async destroyAnswer(questionnaireId: string, answerId: string) {
    return this.httpService.delete<QuestionnaireDto>(`/admin/questionnaires/${questionnaireId}/answers/${answerId}`)
  }

  async sortOrderAnswer(questionnaireId: string, answerIds: string[]) {
    return await this.httpService.put<Boolean>(`/admin/questionnaires/${questionnaireId}/answers/reorder`, { answerIds })
  }

  async setEnabledAnswer(questionnaireId: string, answerId: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.put(`/admin/questionnaires/${questionnaireId}/answers/${answerId}/enable`)
    }
    
    return await this.httpService.put(`/admin/questionnaires/${questionnaireId}/answers/${answerId}/disable`)
  }
}
