import * as _ from 'lodash'
import { FileDto } from '../common/file.dto'
import { TranslationDto } from '../common/translation.dto'

export class ServiceDto {
  id:string
  title: TranslationDto
  thumbnail: FileDto
  thumbnailId: string
  isEnabled: boolean
  appointmentType: string
  type: string 

  static create() {
    const service = new ServiceDto()

    service.title = TranslationDto.create()
    service.isEnabled = true
    
    return service
  }
}
