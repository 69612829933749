<nb-layout withScroll>
  <nb-layout-header fixed>
    <mms-header></mms-header>
  </nb-layout-header>

  <nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive>
    <nb-menu [items]="menu" [tag]="'merchantMenu'"></nb-menu>
  </nb-sidebar>

  <nb-layout-column [class.p-0]="mode == 'FULL_MODE'">
    <nb-alert *ngIf="error" status="danger">
      {{ error.message }}
    </nb-alert>
    <router-outlet></router-outlet>
  </nb-layout-column>
</nb-layout>
