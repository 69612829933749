import { Injectable } from '@angular/core'

import { HttpService } from './http.service'

import { PageResultDto, FeedbackDto, PageDto } from '../dto'
import { PagingDto } from '../dto/common/paging.dto'
import { ReviewedDto } from '../dto/feedback/feedback.dto'


@Injectable()
export class FeedbackService {
  constructor(
    private httpService: HttpService) {
  }

  async create(createFeedback: FeedbackDto) {
    return await this.httpService.post<FeedbackDto>(`/admin/feedbacks`, createFeedback)
  }

  async find() {
    return await this.httpService.get<FeedbackDto[]>('/admin/feedbacks')
  }

  async reviewed(pagingDto: PagingDto) {
    return await this.httpService.get<PageResultDto<ReviewedDto>>('/admin/feedbacks/reviewed', pagingDto)
  }

  async findOne(feedbackId: string) {
    return this.httpService.get<FeedbackDto>(`/admin/feedbacks/${feedbackId}`)
  }

  async update(feedbackId: string, updateFeedback: FeedbackDto) {
    return await this.httpService.put<boolean>(`/admin/feedbacks/${feedbackId}`, updateFeedback)
  }

  async destroy(feedbackId: string) {
    return this.httpService.delete<FeedbackDto>(`/admin/feedbacks/${feedbackId}`)
  }

  async sortOrder(questionIds: string[]) {
    return await this.httpService.put<Boolean>(`/admin/feedbacks/reorder`, { questionIds })
  }

  async setEnabled(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.put(`/admin/feedbacks/${id}/required`)
    }
    
    return await this.httpService.put(`/admin/feedbacks/${id}/optional`)
  }

}
