import { NbMenuItem } from '@nebular/theme'
import { RoleNames } from '../../@api/dto/user/user.dto'

export const LAYOUT_MODULE_CONFIG = 'LAYOUT_MODULE_CONFIG'

export const FULL_MODE = 'FULL_MODE'
export const COLUMN_MODE = 'COLUMN_MODE'

export class MenuItem extends NbMenuItem {
    tag?: string
    image?: string
    declare children?: MenuItem[]
    roles?: RoleNames[]
}