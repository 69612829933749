import * as _ from 'lodash'
import { TranslationDto } from '../common/translation.dto'

export class PageDto {
  id:string
  title: TranslationDto
  content: TranslationDto
  effectiveDate: string
  slug: string

  static create() {
    const page = new PageDto()

    page.title = TranslationDto.create()
    page.content = TranslationDto.create()
    
    return page
  }
}
