import { BaseDto } from "../common/base.dto"
import { TranslationDto } from "../common/translation.dto"
import { FileDto } from "../common/file.dto"

export class MembershipDto extends BaseDto {
  title: TranslationDto
  description: TranslationDto
  feeDescription: TranslationDto
  thumbnail: FileDto
  type: string 

  static create() {
    const user = new MembershipDto()
    
    user.title = { en: '', kh: '' },
    user.description = { en: '', kh: '' }
    user.feeDescription = { en: '', kh: '' },
    user.thumbnail = FileDto.create()
    user.type = ''

    return user
  }
}