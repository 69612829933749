import * as _ from 'lodash'
import { TranslationDto } from '../common/translation.dto'
import { BaseDto } from '../common/base.dto'

export class FeedbackDto {
  id?:string
  question?: TranslationDto
  isRequired?: boolean

  static create() {
    const department = new FeedbackDto()
    department.question = TranslationDto.create()
    department.isRequired = false
    return department
  }
}
export class ReviewedDto extends BaseDto {
  reviewedNo: number
  data?: any[]

  static create() {
    const department = new ReviewedDto()
    department.data = []
    return department
  }
}

