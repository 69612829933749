import { Injectable } from '@angular/core'

import { HttpService } from './http.service'

import { DepartmentDto } from '../dto/department/department.dto'
import { PageResultDto } from '../dto'
import { PagingDto } from '../dto/common/paging.dto'
import { FeaturedDepartmentDto } from '../dto/department/feature-department.dto'
import { FindDepartmentDto } from '../dto/department/find-department.dto'

@Injectable()
export class DepartmentService {
  constructor(
    private httpService: HttpService) {
  }

  async create(createDepartment: DepartmentDto) {
    return await this.httpService.post<DepartmentDto>(`/admin/Departments`, createDepartment)
  }

  async find(findDepartmentDto: FindDepartmentDto ) {
    return await this.httpService.get<PageResultDto<DepartmentDto>>('/admin/departments', findDepartmentDto)
  }

  async findParent(pagingDto: PagingDto ) {
    return await this.httpService.get<PageResultDto<DepartmentDto>>('/admin/departments/parent', pagingDto)
  }

  async findAll(findDepartmentDto: FindDepartmentDto ) {
    return await this.httpService.get<DepartmentDto[]>('/admin/departments/all', findDepartmentDto)
  }

  async findAllParent() {
    return await this.httpService.get<DepartmentDto[]>('/admin/departments/parent/all')
  }

  async findOne(DepartmentId: string) {
    return this.httpService.get<DepartmentDto>(`/admin/departments/${DepartmentId}`)
  }

  async sortOrder(departmentIds: string[]) {
    return await this.httpService.put<Boolean>('/admin/departments/featured/reorder', { departmentIds })
  }

  async createFeature({ departmentId }: FeaturedDepartmentDto) {
    return await this.httpService.post<Boolean>('/admin/departments/featured', { departmentId })
  }

  async findFeature() {
    return await this.httpService.get<DepartmentDto[]>('/admin/departments/featured')
  }

  async sortOrderDepartment(departmentIds: string[]) {
    return await this.httpService.put<Boolean>(`/admin/departments/reorder`, { departmentIds })
  }

  async deleteFeature(id: string) {
    return await this.httpService.delete<Boolean>(`/admin/departments/featured/${id}`)
  }

  async update(DepartmentId: string, updateDepartment: DepartmentDto) {
    return await this.httpService.put<boolean>(`/admin/Departments/${DepartmentId}`, updateDepartment)
  }

  async check(DepartmentId: string) {
    return await this.httpService.put<boolean>(`/admin/Departments/${DepartmentId}/check`)
  }

  async destroy(DepartmentId: string) {
    return this.httpService.delete<DepartmentDto>(`/admin/Departments/${DepartmentId}`)
  }

  async published(id: string) {
    return await this.httpService.put(`/admin/Departments/${id}/published`)
  }

  async setEnabled(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.put(`/admin/Departments/${id}/enable`)
    }
    
    return await this.httpService.put(`/admin/Departments/${id}/disable`)
  }
}
