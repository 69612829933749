<span *ngIf="menuItem.group">
  <nb-icon class="menu-icon" [config]="menuItem.icon" *ngIf="menuItem.icon"></nb-icon>
  {{ menuItem.title }}
</span>
<a *ngIf="menuItem.link && !menuItem.url && !menuItem.children && !menuItem.group"
   [routerLink]="menuItem.link"
   [queryParams]="menuItem.queryParams"
   [fragment]="menuItem.fragment"
   [skipLocationChange]="menuItem.skipLocationChange"
   [attr.target]="menuItem.target"
   [attr.title]="menuItem.title"
   [class.active]="menuItem.selected"
   (mouseenter)="onHoverItem(menuItem)"
   (click)="onItemClick(menuItem);">
  <img *ngIf="menuItem.image" class="mr-2" width="30" [src]="menuItem.image"/>
  <nb-icon class="menu-icon" [config]="menuItem.icon" *ngIf="menuItem.icon"></nb-icon>
  <span class="menu-title">{{ menuItem.title }}
  </span>
  <div class="badge-cross" *ngIf="menuItem.badge">
    <span class="text-badge">{{ menuItem.badge }}</span>
  </div>


</a>
<a *ngIf="menuItem.url && !menuItem.children && !menuItem.link && !menuItem.group"
   [attr.href]="menuItem.url"
   [attr.target]="menuItem.target"
   [attr.title]="menuItem.title"
   [class.active]="menuItem.selected"
   (mouseenter)="onHoverItem(menuItem)"
   (click)="onSelectItem(menuItem)">
  <img *ngIf="menuItem.image" class="mr-2" width="30" [src]="menuItem.image"/>
  <nb-icon class="menu-icon" [config]="menuItem.icon" *ngIf="menuItem.icon"></nb-icon>
  <span class="menu-title">{{ menuItem.title }}</span>
  <div class="badge-cross" *ngIf="menuItem.badge">
    <span class="text-badge">{{ menuItem.badge }}</span>
  </div>
</a>
<a *ngIf="!menuItem.children && !menuItem.link && !menuItem.url && !menuItem.group"
   [attr.target]="menuItem.target"
   [attr.title]="menuItem.title"
   [class.active]="menuItem.selected"
   (mouseenter)="onHoverItem(menuItem)"
   (click)="$event.preventDefault(); onItemClick(menuItem);">
  <img *ngIf="menuItem.image" class="mr-2" width="30" [src]="menuItem.image"/>
  <nb-icon class="menu-icon" [config]="menuItem.icon" *ngIf="menuItem.icon"></nb-icon>
  <span class="menu-title">{{ menuItem.title }}</span>
  <div class="badge-cross" *ngIf="menuItem.badge">
    <span class="text-badge">{{ menuItem.badge }}</span>
  </div>
</a>
<a *ngIf="menuItem.children"
   (click)="$event.preventDefault(); onToggleSubMenu(menuItem);"
   [attr.target]="menuItem.target"
   [attr.title]="menuItem.title"
   [class.active]="menuItem.selected"
   (mouseenter)="onHoverItem(menuItem)"
   href="#">
  <img *ngIf="menuItem.image" class="mr-2" width="30" [src]="menuItem.image"/>
  <nb-icon class="menu-icon" [config]="menuItem.icon" *ngIf="menuItem.icon"></nb-icon>
  <span class="menu-title">{{ menuItem.title }}</span>
  <div class="badge-cross" *ngIf="menuItem.badge">
    <span class="text-badge">{{ menuItem.badge }}</span>
  </div>
  <nb-icon class="expand-state" [icon]="getExpandStateIcon()" pack="nebular-essentials"></nb-icon>
</a>
<ul *ngIf="menuItem.children"
    [class.collapsed]="!(menuItem.children && menuItem.expanded)"
    [class.expanded]="menuItem.expanded"
    [@toggle]="toggleState"
    class="menu-items">
  <ng-container *ngFor="let item of menuItem.children">
    <li nbMenuItem *ngIf="!item.hidden"
        [menuItem]="item"
        [class.menu-group]="item.group"
        (hoverItem)="onHoverItem($event)"
        (toggleSubMenu)="onToggleSubMenu($event)"
        (selectItem)="onSelectItem($event)"
        (itemClick)="onItemClick($event)"
        class="menu-item">
        <div class="badge-cross" *ngIf="item.badge">
          <span class="text-badge">{{ menuItem.badge }}</span>
        </div>
    </li>
   
  </ng-container>
</ul>