import * as _ from 'lodash'
import { FileDto } from '../common/file.dto'
import { TranslationDto } from '../common/translation.dto'

export class ArticleDto {
  id:string
  title: TranslationDto
  content: TranslationDto
  thumbnail: FileDto
  thumbnailId: string
  publishDate: string
  isPublished: boolean
  isEnabled: boolean

  static create() {
    const page = new ArticleDto()

    page.title = TranslationDto.create()
    page.content = TranslationDto.create()
    page.isPublished = false
    page.isEnabled = true
    
    return page
  }
}
