import { Injectable } from '@angular/core'

import { HttpService } from './http.service'

import { PageResultDto } from '../dto'
import { PagingDto } from '../dto/common/paging.dto'
import { PackageDto } from '../dto/package/package.dto'
import { FindPackageDto } from '../dto/package/find-package.dto'

@Injectable()
export class PackageService {
  constructor(
    private httpService: HttpService) {
  }

  async create(createPackage: PackageDto) {
    return await this.httpService.post<PackageDto>(`/admin/packages`, createPackage)
  }

  async find(pagingDto: FindPackageDto) {
    return await this.httpService.get<PageResultDto<PackageDto>>('/admin/packages', pagingDto)
  }

  async findOne(packageId: string) {
    return this.httpService.get<PackageDto>(`/admin/packages/${packageId}`)
  }

  async update(packageId: string, updatePackage: PackageDto) {
    return await this.httpService.put<boolean>(`/admin/packages/${packageId}`, updatePackage)
  }

  async destroy(packageId: string) {
    return this.httpService.delete<PackageDto>(`/admin/packages/${packageId}`)
  }

  async published(id: string) {
    return await this.httpService.put(`/admin/packages/${id}/published`)
  }

  async setEnabled(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.put(`/admin/packages/${id}/enable`)
    }
    
    return await this.httpService.put(`/admin/packages/${id}/disable`)
  }

}
