import { Injectable } from '@angular/core'

import { HttpService } from './http.service'

import { DashboardDto } from '../dto'
import { FindDashboardDto } from '../dto/dashboard/find-dashboard.dto'


@Injectable()
export class DashboardService {
  constructor(
    private httpService: HttpService) {
  }

  async find(findDashboardDto: FindDashboardDto) {
    return await this.httpService.get<DashboardDto>('/admin/dashboard/overview', findDashboardDto)
  }
}
