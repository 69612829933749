import { Injectable } from "@angular/core"

import { HttpService } from "./http.service"
import { PagingDto } from "../dto/common/paging.dto"
import { AllowPhoneNumberDto } from "../dto/allow-phone-number/allow-phone-number.dto"
import { PageResultDto } from "../dto"

@Injectable()
export class AllowPhoneNumberService {
  constructor(
    private httpService: HttpService
    ) {
  }

  async find(pagingDto: PagingDto) {
    return await this.httpService.get<PageResultDto<AllowPhoneNumberDto>>('/admin/allow-phone-numbers', pagingDto)
  }

  async update(id: string, allowPhoneNumberDto: AllowPhoneNumberDto) {
    return await this.httpService.put<Boolean>(`/admin/allow-phone-numbers/${id}`, allowPhoneNumberDto)
  }

  async create(allowPhoneNumberDto: AllowPhoneNumberDto) {
    return await this.httpService.post<AllowPhoneNumberDto>(`/admin/allow-phone-numbers`, allowPhoneNumberDto)
  }

  async delete(id: string) {
    return await this.httpService.delete<Boolean>(`/admin/allow-phone-numbers/${id}`)
  }
}