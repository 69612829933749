import { Injectable } from '@angular/core'

import { HttpService } from './http.service'

import { PageResultDto, ArticleDto } from '../dto'
import { PagingDto } from '../dto/common/paging.dto'


@Injectable()
export class ArticleService {
  constructor(
    private httpService: HttpService) {
  }

  async create(createArticle: ArticleDto) {
    return await this.httpService.post<ArticleDto>(`/admin/articles`, createArticle)
  }

  async find(pagingDto: PagingDto) {
    return await this.httpService.get<PageResultDto<ArticleDto>>('/admin/articles', pagingDto)
  }

  async findOne(articleId: string) {
    return this.httpService.get<ArticleDto>(`/admin/articles/${articleId}`)
  }

  async update(articleId: string, updateArticle: ArticleDto) {
    return await this.httpService.put<boolean>(`/admin/articles/${articleId}`, updateArticle)
  }

  async destroy(articleId: string) {
    return this.httpService.delete<ArticleDto>(`/admin/articles/${articleId}`)
  }

  async published(id: string) {
    return await this.httpService.put(`/admin/articles/${id}/published`)
  }

  async setEnabled(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.put(`/admin/articles/${id}/enable`)
    }
    
    return await this.httpService.put(`/admin/articles/${id}/disable`)
  }

  async pushNotification(id: string) {
    return await this.httpService.post(`/admin/articles/${id}/push-notification`)
  }

}
