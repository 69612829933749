import { Injectable } from '@angular/core'

import { HttpService } from './http.service'

import { PageDto, PageResultDto } from '../dto'
import { PagingDto } from '../dto/common/paging.dto'

@Injectable()
export class PageService {
  constructor(
    private httpService: HttpService) {
  }

  async find(pagingDto: PagingDto) {
    return await this.httpService.get<PageResultDto<PageDto>>('/admin/pages', pagingDto)
  }

  async findOne(pageId: string) {
    return this.httpService.get<PageDto>(`/admin/pages/${pageId}`)
  }

  async update(pageId: string, updatePageDto: PageDto) {
    return await this.httpService.put<PageDto>(`/admin/pages/${pageId}`, updatePageDto)
  }
}
