import { Injectable } from '@angular/core'

import { HttpService } from './http.service'

import { PageResultDto, SymptomDto } from '../dto'
import { PagingDto } from '../dto/common/paging.dto'


@Injectable()
export class SymptomService {
  constructor(
    private httpService: HttpService) {
  }

  async create(createSymptom: SymptomDto) {
    return await this.httpService.post<SymptomDto>(`/admin/symptoms`, createSymptom)
  }

  async find() {
    return await this.httpService.get<SymptomDto[]>('/admin/symptoms')
  }

  async findOne(SymptomId: string) {
    return this.httpService.get<SymptomDto>(`/admin/symptoms/${SymptomId}`)
  }

  async sortOrder(symptomIds: string[]) {
    return await this.httpService.put<Boolean>(`/admin/symptoms/reorder`, { symptomIds })
  }

  async update(SymptomId: string, updateSymptom: SymptomDto) {
    return await this.httpService.put<boolean>(`/admin/symptoms/${SymptomId}`, updateSymptom)
  }

  async destroy(SymptomId: string) {
    return this.httpService.delete<SymptomDto>(`/admin/symptoms/${SymptomId}`)
  }

  async setEnabled(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.put(`/admin/symptoms/${id}/enable`)
    }
    
    return await this.httpService.put(`/admin/symptoms/${id}/disable`)
  }
}
