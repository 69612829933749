import { Injectable } from '@angular/core'

import { HttpService } from './http.service'

import { PageDto, PageResultDto } from '../dto'
import { PagingDto } from '../dto/common/paging.dto'
import { MembershipDto } from '../dto/membership/membership.dto'
import { MembershipDetailDto } from '../dto/membership/membership-detail.dto'
import { MembershipFeatureDto } from '../dto/membership/membership-feature.dto'
import { UpdateMembershipDto } from '../dto/membership/update-membership.dto'

@Injectable()
export class MembershipService {
  constructor(
    private httpService: HttpService) {
  }

  async find(pagingDto: PagingDto) {
    return await this.httpService.get<PageResultDto<MembershipDto>>('/admin/memberships', pagingDto)
  }

  async findOne(membershipId: string) {
    return this.httpService.get<MembershipDetailDto>(`/admin/memberships/${membershipId}`)
  }

  async update(membershipId: string, updateMembershipDto: UpdateMembershipDto) {
    return await this.httpService.put<MembershipDto>(`/admin/memberships/${membershipId}`, updateMembershipDto)
  }

  async createFeature(membershipId: string, membershipFeatureDto: MembershipFeatureDto) {
    return await this.httpService.post<MembershipDto>(`/admin/memberships/${membershipId}/feature`, membershipFeatureDto)
  }

  async updateFeature(membershipId: string, membershipFeatureId: string, membershipFeatureDto: MembershipFeatureDto) {
    return await this.httpService.put<MembershipDto>(`/admin/memberships/${membershipId}/feature/${membershipFeatureId}`, membershipFeatureDto)
  }

  async deleteFeature(membershipId: string, membershipFeatureId: string) {
    return await this.httpService.delete<MembershipDto>(`/admin/memberships/${membershipId}/feature/${membershipFeatureId}`)
  }
}
