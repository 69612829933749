import * as _ from 'lodash'

import { TranslationDto } from '../common/translation.dto'
import { SymptomDto } from '../symptom/symptom.dto'
import { BaseDto } from '../common/base.dto'

export class QuestionnaireDto extends BaseDto {
  question: _.Dictionary<string>
  symptom: SymptomDto
  answers: AnswerDto[]
  symptomId: string
  isEnabled: boolean
  isSuggested: boolean

  static create() {
    const question = new QuestionnaireDto()

    question.question = TranslationDto.create()
    question.isSuggested = false
    question.isEnabled = false
    question.answers = []
    
    return question
  }
}

export class AnswerDto extends BaseDto {
  answer: _.Dictionary<string>
  isEnabled: boolean

  static create() {
    const answer = new AnswerDto()

    answer.answer = TranslationDto.create()
    answer.isEnabled = true
    
    return answer
  }
}



