import * as _ from 'lodash'
import { FileDto } from '../common/file.dto'
import { TranslationDto } from '../common/translation.dto'
import { DepartmentDto } from '../department/department.dto'
import { DoctorDescriptionDto } from './doctor-description.dto'
import { BaseDto } from '../common/base.dto'

export class DoctorDto extends BaseDto {
  fullName: string
  department: DepartmentDto
  profile: FileDto
  startYear: number
  nationality: string
  languages: string
  banner: FileDto
  doctorNo: string
  isEnabled: boolean
  departmentId: string
  isExpDisabled: boolean
  descriptions: DoctorDescriptionDto[]
  profileId: string
  bannerId: string

  static create() {
    const doctor = new DoctorDto()

    doctor.fullName = ''
    doctor.isEnabled = true
    doctor.isExpDisabled = false
    doctor.descriptions = [ DoctorDescriptionDto.create() ]
    doctor.department = DepartmentDto.create()
    doctor.doctorNo = ''

    return doctor
  }
}
