import { BaseDto } from '../common/base.dto'
import { FileDto } from '../common/file.dto'
import { MembershipDto } from '../membership/membership.dto'

export class UserDto extends BaseDto {
  firstName: string
  lastName: string
  status: string 
  photo: FileDto
  patientId: string
  dateOfBirth: string
  nationality: string
  firstLang: string 
  secondLang: string
  address: string
  phoneNumber: string
  membership: MembershipDto
  roleNames: string[]
  membershipExpireDate: string
  isExpired: boolean
  enabled: boolean
  email: string
  emergencyNumber: string
  gender: string
  occupation: string
  age: number
  idCard: string
  transactionId: string
  roleName: RoleNames
  photoId: string

  static create() {
    const user = new UserDto()
    
    user.firstName = '',
    user.lastName = ''
    user.email = ''
    user.roleNames = []
    user.membership = MembershipDto.create()

    return user
  }
}
export class CreateUserDto {
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  password: string
  roleName: string
  photoId?: string

  static create(data: CreateUserDto) {
    return Object.assign(new CreateUserDto(), data)
  }
}

export class UpdateUserDto {
  firstName :string 
  lastName: string
  email: string
  roleName?: string
  photoId?: string

  static create(data: CreateUserDto) {
    return Object.assign(new CreateUserDto(), data)
  }
}

export enum RoleNames {
  SUPER_ADMIN = 'SUPER_ADMIN',
  CUSTOMER = 'CUSTOMER',
  ADMIN = 'ADMIN',
  MARKETING = 'MARKETING'
}

export enum UserStatus {
  APPROVED = 'approved',
  REJECTED = 'rejected',
  PENDING = 'pending'
}

export class AuthDto extends BaseDto {
  user: UserDto
  sessionToken: string

  static create() {
    return new UserDto()
  }
}