import * as _ from 'lodash'
import { TranslationDto } from '../common/translation.dto'

export class DoctorDescriptionDto {
  id:string
  title: TranslationDto
  description: TranslationDto
  sortOrder: number

  static create(sortOrder?: number) {
    const doctor = new DoctorDescriptionDto()

    doctor.title = TranslationDto.create()
    doctor.description = TranslationDto.create()
    doctor.sortOrder = sortOrder ?? 1
    return doctor
  }
}
