import { Pipe, PipeTransform } from '@angular/core'

import * as _ from 'lodash' 

const PROFILE_PLACEHOLDER = '/assets/images/users.png'

@Pipe({name: 'profilePlaceholder'})
export class ProfilePlaceholderPipe implements PipeTransform {
  public transform(value: string) {
    return value || PROFILE_PLACEHOLDER
  }
}