import * as _ from 'lodash'
import { BaseDto } from '../common/base.dto'
import { FileDto } from '../common/file.dto'
import { PagingDto } from '../common/paging.dto'


export class BannerDto extends BaseDto {
  image?: FileDto
  titleEn?: string
  titleKh?: string
  enabled?: boolean
  data?: { packageId: string }

  static create(data: BannerDto) {
    return _.assign(new BannerDto(), data)
  }
}

export class FindBannerDto {
  placement: string
}

export class CreateBannerDto {
  imageId?: string
  placement?: string
  titleEn?: string
  titleKh?: string
  packageId?: string
}