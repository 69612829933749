import { Injectable } from '@angular/core'

import { HttpService } from './http.service'
import { InvoiceDto, FindInvoiceDto, PageResultDto } from '../dto'

@Injectable()
export class InvoiceService {
  constructor(
    private httpService: HttpService) {
  }

  async find(findInvoiceDto: FindInvoiceDto) {
    return await this.httpService.get<PageResultDto<InvoiceDto>>('/admin/invoices', findInvoiceDto)
  }

  async findOne(invoiceId: string) {
    return this.httpService.get<InvoiceDto>(`/admin/invoices/${invoiceId}`)
  }

  async generateInvoice() {
    return this.httpService.post<InvoiceDto>(`/admin/mock/invoices`)
  }
}
