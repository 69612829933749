import { Routes } from '@angular/router'

export const ROUTES: Routes = [
  {
    path: '',
    loadChildren: () => import ('./+admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: 'logger',
    loadChildren: () => import ('./+logger/logger.module').then(m => m.LoggerModule)
  }
]
