import { Injectable } from '@angular/core'

import { HttpService } from './http.service'

import { AppointmentDto, FindAppointmentDto, PageResultDto } from '../dto'

@Injectable()
export class AppointmentService {
  constructor(
    private httpService: HttpService) {
  }

  async find(findAppointmentDto: FindAppointmentDto) {
    return await this.httpService.get<PageResultDto<AppointmentDto>>('/admin/appointments', findAppointmentDto)
  }

  async findOne(appointmentId: string) {
    return this.httpService.get<AppointmentDto>(`/admin/appointments/${appointmentId}`)
  }

  async updateStatus(appointmentId: string, status: string, doctorId?: string) {
    return this.httpService.put<AppointmentDto>(`/admin/appointments/${appointmentId}/status`, { status, doctorId })
  }

  async mockAppointment() {
    return this.httpService.post<AppointmentDto>(`/admin/mock/appointment`)
  }

  async removeAllAppointment() {
    return this.httpService.delete<AppointmentDto>(`/admin/mock/appointment`)
  }
}
