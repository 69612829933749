export { AuthService } from './auth.service'
export { HttpService } from './http.service'
export { LocalStorageService } from './local-storage.service'
export { FileService } from './file.service'
export { UserService } from './user.service'
export { ProfileService } from './profile.service'
export { PageService } from './page.service'
export { SettingService } from './setting.service'
export { MembershipService } from './membership.service'
export { DepartmentService } from './department.service'
export { DoctorService } from './doctor.service'
export { FeedbackService } from './feedback.service'
export { ServiceService } from './service.service'
export { QuestionnaireService } from './questionnaire.service'
export { AppointmentService } from './appointment.service'
export { InvoiceService } from './invoice.service'
export { DashboardService } from './dashboard.service'
export { AllowPhoneNumberService } from './allow-phone-number.service'