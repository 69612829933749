import * as _ from 'lodash'
import { FileDto } from '../common/file.dto'
import { TranslationDto } from '../common/translation.dto'

export class DepartmentDto {
  id:string
  title: TranslationDto
  description: TranslationDto
  thumbnail: FileDto
  thumbnailId: string
  enabled: boolean
  parent: DepartmentDto
  parentId: string

  static create() {
    const department = new DepartmentDto()

    department.title = TranslationDto.create()
    department.description = TranslationDto.create()
    department.enabled = true

    return department
  }
}
