import { Injectable } from '@angular/core'

import { UserDto, PageResultDto, FindUserDto, CreateUserDto, UpdateUserDto, DateFilterDto } from '../dto'

import { HttpService } from './http.service'
import { ExportExcelResultDto } from '../dto/common/export-excel-result.dto'

@Injectable()
export class UserService {
  constructor(
    private httpService: HttpService) {
  }

  async find(findUserDto: FindUserDto) {
    return await this.httpService.get<PageResultDto<UserDto>>('/admin/users', findUserDto)
  }

  async findOne(id: string) {
    return this.httpService.get<UserDto>(`/admin/users/${id}`)
  }

  async rejected(id: string) {
    return this.httpService.post<boolean>(`/admin/users/${id}/rejected`)
  }

  async update(id: string, patientId: string) {
    return await this.httpService.put(`/admin/users/${id}`, { patientId })
  }

  async exportExcel(dateFilterDto: DateFilterDto) {
    return this.httpService.get<ExportExcelResultDto>(`/admin/users/export`, dateFilterDto)
  }

  async setEnabled(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.put(`/admin/users/${id}/enable`)
    }
    
    return await this.httpService.put(`/admin/users/${id}/disable`)
  }

  async changePassword(id: string, newPassword: string) {
    return await this.httpService.patch(`/admin/users/${id}/password`, { password: newPassword })
  }

  // async findByIds(ownerIds: string[]) {
  //   return await this.httpService.get<UserDto[]>(`/admin/users/ids`, { ownerIds })
  // }

  async updateAdmin(id: string,userDto: UpdateUserDto) {
    return await this.httpService.put(`/admin/users/${id}/operator`, userDto)
  }

  async create(createUserDto: CreateUserDto) {
    return await this.httpService.post<UserDto>('/admin/users', createUserDto)
  }

  async delete(id: string) {
    return await this.httpService.delete<Boolean>(`/admin/users/${id}`)
  }
}
