import { Injectable } from '@angular/core'

import { HttpService } from './http.service'

import { PageResultDto, ServiceDto } from '../dto'
import { PagingDto } from '../dto/common/paging.dto'


@Injectable()
export class ServiceService {
  constructor(
    private httpService: HttpService) {
  }

  async create(createService: ServiceDto) {
    return await this.httpService.post<ServiceDto>(`/admin/services`, createService)
  }

  async find(pagingDto: PagingDto) {
    return await this.httpService.get<PageResultDto<ServiceDto>>('/admin/services', pagingDto)
  }

  async findOne(articleId: string) {
    return this.httpService.get<ServiceDto>(`/admin/services/${articleId}`)
  }

  async update(articleId: string, updateService: ServiceDto) {
    return await this.httpService.put<boolean>(`/admin/services/${articleId}`, updateService)
  }

  async destroy(articleId: string) {
    return this.httpService.delete<ServiceDto>(`/admin/services/${articleId}`)
  }

  async setEnabled(id: string, enabled: boolean) {
    if (enabled) {
      return await this.httpService.put(`/admin/services/${id}/enable`)
    }
    
    return await this.httpService.put(`/admin/services/${id}/disable`)
  }
}
