import { Injectable } from "@angular/core"

import { PageResultDto, BannerDto, FindBannerDto, CreateBannerDto } from "../dto"
import { HttpService } from "./http.service"
import { LogsDto, FindAdminLogs } from "../dto/logs/logs.dto"
import { PagingDto } from "../dto/common/paging.dto"
import { ExportExcelResultDto } from "../dto/common/export-excel-result.dto"


@Injectable()
export class LogsService {
  constructor(
    private httpService: HttpService
    ) {
  }

  async find(findAdminLogs: FindAdminLogs) {
    return await this.httpService.get<PageResultDto<LogsDto>>('/admin/logs', findAdminLogs)
  }

  async download(id: string) {
    return await this.httpService.get<ExportExcelResultDto>(`/admin/logs/${id}/download-json`)
  }
}