import { FileDto } from '../common/file.dto'
import * as _ from 'lodash'

export class ProfileDto {
  firstName: string
  lastName: string
  email?: string
  photo: FileDto
  roleNames: string[]
  id: string

  static createForm({ id, firstName, lastName, email, roleNames, photo }: ProfileDto) {
    return _.assign<ProfileDto>({
      id,
      firstName,
      lastName,
      email,
      photo,
      roleNames
    })
  }

}

import { assign } from 'lodash'

export class UpdateProfileDto {
  firstName: string
  lastName: string

  static create({firstName, lastName}: ProfileDto ) {
    return assign<UpdateProfileDto>({
      firstName,
      lastName,
    })
  }
}
