import { BaseDto } from '../common/base.dto'
import { UserDto } from '../user/user.dto'

export class InvoiceDto extends BaseDto {
  invoiceNo: string
  transactionId: string
  referentId: string
  status: string
  total: number
  admissionDate: string
  date:string
  time: string
  user: UserDto
  payment: PaymentDto
  

  static create() {
    const invoice = new InvoiceDto()

    invoice.user = UserDto.create()
    invoice.payment = PaymentDto.create()

    return invoice
  }
}

export class PaymentDto extends BaseDto {
  transactionId: string
  referentId: string
  status: string
  data: any
  paymentMethod: string

  static create() {
    return new PaymentDto()
  }
}

export enum InvoiceStatus {
  PAID = 'paid',
  UNPAID = 'unpaid',
}