import { BaseDto } from '../common/base.dto'
import { FileDto } from '../common/file.dto'
import { DepartmentDto } from '../department/department.dto'
import { DoctorDto } from '../doctor/doctor.dto'
import { ServiceDto } from '../service/service.dto'
import { SymptomDto } from '../symptom/symptom.dto'
import { UserDto } from '../user/user.dto'

export class AppointmentDto extends BaseDto {
  appointmentType: string
  appointmentNo: string
  status: string
  schedule: ScheduleDto
  note: string
  department: DepartmentDto
  patientId: string
  serviceName: string
  doctor: DoctorDto
  service: ServiceDto
  symptom: SymptomDto
  user: UserDto
  interviews: any[]
  files: FileDto[]
  transactionId: string
  isHospitalAssigned: boolean

  static create() {
    const appointment = new AppointmentDto()

    appointment.user = UserDto.create()
    appointment.schedule = ScheduleDto.create()
    appointment.department = DepartmentDto.create()
    appointment.doctor = DoctorDto.create()
    appointment.service = ServiceDto.create()
    appointment.symptom = SymptomDto.create()
    appointment.files = []

    return appointment
  }
}

export class ScheduleDto {
  dateTime: string
  dateOnly: string

  static create() {
    return new ScheduleDto()
  }
}